import style from "../style/userPin.module.css";

import { motion } from "framer-motion";

import { RiMapPin2Fill } from "react-icons/ri";

import { Avatar, Tooltip } from "@chakra-ui/react";
import { auto } from "@popperjs/core";

interface Props {
	name: string;
	height: string;
	tooltipText?: JSX.Element;
	profilePicture?: string;
}

const getCustomInitials = (name: string): string => {
	if (name.replace(" ", "").match(/^\+\d+$/)) {
		return name.replace(" ", "");
	} else {
		return name
			.split(" ")
			.map((part) => part[0])
			.join("");
	}
};

function calculateXPosition(yPosPercent: number, baseWidthPx: number) {
	const maxWidthAtY = baseWidthPx * (1 - yPosPercent / 100);
	console.log(maxWidthAtY);
	const xPosPx = Math.random() * (maxWidthAtY / 2);
	console.log("xposX:" + xPosPx);
	const xPosPercent = ((xPosPx / baseWidthPx) * 100 + 45 * (1 - yPosPercent / 100)) / 2.5;
	console.log("xposXP:" + xPosPercent);
	const side = Math.random() < 0.5 ? "left" : "right";

	return { side: side, pos: xPosPercent };
}

const UserPin: React.FC<Props> = ({ name, tooltipText, height, profilePicture }) => {
	const HeightNumber = Number.parseInt(height.replace("%", ""));
	var placementStyle = {};
	if (HeightNumber && HeightNumber > 0) {
		var placement = calculateXPosition(HeightNumber, 840);
		placementStyle = {
			[placement.side]: `${placement.pos}%`,
		};
	}

	return (
		<Tooltip placement="top" label={tooltipText} closeDelay={300} width={300} overflow={auto}>
			<motion.div
				className={style.userPin}
				initial={{ bottom: 0 }}
				animate={{ bottom: height }}
				transition={{ delay: 0.8, duration: 1.5 }}
				style={placementStyle}
			>
				<motion.div whileHover={{ scale: 1.1, marginTop: -5.2 }}>
					<RiMapPin2Fill size={75} className={style.icon} />
					<Avatar
						size={"md"}
						name={name}
						className={style.profilePicture}
						getInitials={getCustomInitials}
						src={profilePicture ? `data:image/jpeg;base64,${profilePicture}` : ""}
					/>
				</motion.div>
			</motion.div>
		</Tooltip>
	);
};

export default UserPin;
