import Connection from "features/connection";
import { AppMaintenanceMode, AppUrl } from "config/environmentVariables";

import { Center, Badge } from "@chakra-ui/react";

import styles from "./style/ConnectionPage.module.css";

const ConnectionPage = () => {
	console.log(AppMaintenanceMode + " | " + AppUrl);
	return (
		<>
			<Center className={styles.background}>
				{AppMaintenanceMode === "true" ? (
					<Badge variant={"subtle"} style={{ fontSize: "1rem" }}>
						We are currently performing a maintenance. Gamification will be back soon.
					</Badge>
				) : (
					<Connection />
				)}
			</Center>
		</>
	);
};

export default ConnectionPage;
